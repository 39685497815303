export default class Slider {

  constructor($el) {
    this.element = $el;
    this.leftPos = 0;
    this.container = $('<div class="slides"></div>');
    this.track = this.element.find('> ul');
    this.slides = this.track.find('> li');
    this.controls = {
      prev: $('<button type="button" title="zurück"><i class="far fa-angle-left"></i></button>'),
      next: $('<button type="button" title="weiter"><i class="far fa-angle-right"></i></button>')
    }
    // only initialize if there are slides
    if(this.slides.length) {
      this.setDimensions();
      this.setControls();
      this.initTouch();
    }
    else {
      this.element.addClass('empty');
    }
    this.element.addClass('initialized');
  }

  // set layout sizes
  setDimensions() {
    this.element.append(this.container.append(this.track));
    this.slideWidth = this.slides.outerWidth();
    this.trackWidth = this.container.find('> ul > li').length * this.slideWidth;
    this.track.css({width: `${this.trackWidth}px`})
  }

  // add and init previous / next buttons
  setControls() {
    const slider = this;
    const $slidePrev = this.controls.prev.on('click', function () {
      slider.slide('prev');
    });
    const $slideNext = this.controls.next.on('click', function () {
      slider.slide('next');
    });
    this.element.append($('<div class="controls"></div>').append($slidePrev, $slideNext));

    this.setControlStatus();
    $(window).on('resize', function () {
      slider.setControlStatus();
    });
  }

  // en-/disable prev and next buttons depending on slider position
  setControlStatus() {
    // check if we need controls
    if (this.element.outerWidth() > this.trackWidth - 10) {
      this.controls.prev.hide();
      this.controls.next.hide();
    }  else {
      this.controls.prev.show();
      this.controls.next.show();
    }
    // start: disable prev
    if (this.leftPos >= 0) {
      this.controls.prev.addClass('disabled');
    } else {
      this.controls.prev.removeClass('disabled');
    }
    // end: disable next
    if (-this.leftPos + this.element.outerWidth() >= this.trackWidth - 20) {
      this.controls.next.addClass('disabled');
    } else {
      this.controls.next.removeClass('disabled');
    }
  }

  // slide left or right
  slide(direction) {
    let newPos = this.leftPos;
    const containerWidth = this.element.outerWidth();
    const visibleSlides = Math.trunc(containerWidth / this.slideWidth);
    const distance = visibleSlides * this.slideWidth; // slide step = width of completely visible slides
    if (direction === 'prev') {
      newPos = Math.floor(this.leftPos + distance);
      if (newPos > 0) newPos = 0;
    }
    if (direction === 'next') {
      newPos = Math.floor(this.leftPos - distance);
      if (newPos < -this.trackWidth + this.slideWidth) newPos = this.leftPos;
    }
    this.track.css({transform: `translate3d(${newPos}px, 0, 0)`});
    this.leftPos = newPos;
    this.setControlStatus();
  }

  // init mobile touch interface
  initTouch() {
    const slider = this;
    let startOffsetX = 0;
    const threshold = 50; // pixel distance of touchdrag to trigger slide

    // touchdrag handler
    slider.track.on('touchstart', function (event) {
      slider.element.addClass('no-anim');
      startOffsetX = event.changedTouches[0].pageX;
      $(window).on('touchmove', touchDragHandler);
      $(window).on('touchend', touchDragEnd);
    });

    // while dragged
    function touchDragHandler(event) {
      event.stopPropagation();
      const newOffsetX = event.changedTouches[0].pageX;
      const distance = startOffsetX - newOffsetX;
      slider.track.css({transform: `translate3d(${slider.leftPos - distance}px, 0, 0)`});
    }

    // after drag/touch
    function touchDragEnd(event) {
      event.stopPropagation();
      slider.element.removeClass('no-anim');
      const endOffsetX = event.changedTouches[0].pageX;
      if (startOffsetX > endOffsetX + threshold) { // swipe right
        slider.slide('next');
      } else if (startOffsetX < endOffsetX - threshold) { // swipe left
        slider.slide('prev');
      } else {
        slider.track.css({transform: `translate3d(${slider.leftPos}px, 0, 0)`});
      }
      $(window).off('touchmove', touchDragHandler);
      $(window).off('touchend', touchDragEnd);
    }
  }
}
