export default class Synonyms {

  constructor() {
    this.init();
  }

  init() {
    $(document).on('click', '.synonym', function(e) {
      e.preventDefault();
      const searchString = $(this).text();
      const $header = $('.header');
      const $searchToggle = $('.nav-search a.nav-link', $header);
      const $searchInput = $('.search-container .typeahead', $header);
      // scroll to top, oben mobile nav, open search field, set value, trigger typeahead, focus input
      $('html, body').stop().animate({scrollTop: 0}, 200, function() {
        if (!$searchToggle.is(':visible')) {
          $('.navbar-toggler.collapsed', $header).click();
        }
        if (!$header.hasClass('search-open')) {
          $searchToggle.click();
        }
        $searchInput.val(searchString).trigger('input').get(0).focus();
      });
    });
  }
}
