export default class SocialShare {

  constructor() {
    this.targets = {
      facebook: 'https://www.facebook.com/share.php?u={{u}}',
      twitter: 'https://twitter.com/share?url={{u}}',
      linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url={{u}}&title={{t}}',
      xing: 'https://www.xing.com/spi/shares/new?url={{u}}',
      email: 'mailto:?subject={{t}}&body=' +config.miscMessages.shareMail+ ' {{u}}'
    }
    this.size = [640, 528];
    this.init();
  }

  init() {
    const that = this;
    $(document).on('click', '.social-share a', function(e) {
      e.preventDefault();

      const target = $(this).attr('class').split('-')[1];
      const href = $(this).closest('.social-share').data('url') || window.location.href;
      const title = $(this).closest('.social-share').data('title') || document.title || '';

      const url = that.targets[target].replace('{{u}}', encodeURIComponent(href)).replace('{{t}}', encodeURIComponent(title));
      const left = (screen.width / 2) - (that.size[0] / 2);
      const top = (screen.height / 2) - (that.size[1] / 2);
      const width = target === 'email' ? 1 : that.size[0];
      const height = target === 'email' ? 1 : that.size[1];

      const win = window.open(url, 't', `toolbar=0,resizable=1,status=0,copyhistory=no,width=${width},height=${height},top=${top},left=${left}`);

      if (target === 'email') {
        setTimeout(function() {
          win.close();
        }, 1000);
      }
    })
  }
}
