export default class Utils {

  // generate a unique id
  static generateUniqueId(prefix) {
    return `${prefix ? prefix : ''}${Math.random().toString(36).substr(2, 9)}`;
  }

  // show a notification (toast)
  static notify(level, message, hideAfter) {
    const levels = ['danger', 'warning', 'info', 'success'];
    $.toast({
      text: message,
      icon: levels[level],
      hideAfter: hideAfter || 5000,
      position: 'bottom-right'
    });
  }

  // animated move an element up and down with translateY
  static translateY($el, endPos, duration, callback) {
    if (!duration) duration = 500;
    // const transformB4 = $el.css('transform');
    // const transitionB4 = $el.css('transition');
    $el.css('transition', `transform ${duration}ms`);
    $el.css('transform', `translateY(${endPos})`);
    setTimeout(function () {
      // $el.css('transition', transitionB4 || '');
      // $el.css('transform', transformB4 || '');
      $el.removeAttr('style');
      if(callback) callback();
    }, duration);
  }

  // get an URL parameter value
  static getURLParameter(name) {
    const url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

}
