/**
 * @fileoverview Font Awesome 5
 * @link https://fontawesome.com/how-to-use/with-the-api/
 */

import {library, dom} from '../../@fortawesome/fontawesome-svg-core';

// solid
import     {faCaretUp, faCaretDown, faEnvelope, faThumbsUp as farThumbsUp} from '../../@fortawesome/pro-solid-svg-icons';
library.add(faCaretUp, faCaretDown, faEnvelope, farThumbsUp);

// regular
import     {faPlus, faAngleLeft, faAngleRight, faAngleDown, faArrowUp, faArrowDown, faChartLine, faChartLineDown, faCheck, faClock, faCommentAltDots, faCommentAltPlus, faEdit, faEllipsisH, faExclamationCircle, faEye, faEyeSlash, faInfoCircle, faLockAlt, faLongArrowLeft, faLongArrowRight, faPen, faRedo, faSave, faSignIn, faSignOut, faSearch, faThumbsUp, faTimes, faTrashAlt, faUndo, faWindowClose} from '../../@fortawesome/pro-regular-svg-icons';
library.add(faPlus, faAngleLeft, faAngleRight, faAngleDown, faArrowUp, faArrowDown, faChartLine, faChartLineDown, faCheck, faClock, faCommentAltDots, faCommentAltPlus, faEdit, faEllipsisH, faExclamationCircle, faEye, faEyeSlash, faInfoCircle, faLockAlt, faLongArrowLeft, faLongArrowRight, faPen, faRedo, faSave, faSignIn, faSignOut, faSearch, faThumbsUp, faTimes, faTrashAlt, faUndo, faWindowClose);

// brands
import     {faGoogle, faLinkedinIn, faTwitter, faFacebookF, faXing} from '../../@fortawesome/free-brands-svg-icons';
library.add(faGoogle, faLinkedinIn, faTwitter, faFacebookF, faXing);


// // Light
// import { fal } from '@fortawesome/pro-light-svg-icons';
//
// // Regular
// import { far } from '@fortawesome/pro-regular-svg-icons';
//
// // Duotone
// import { fad } from '@fortawesome/pro-duotone-svg-icons';
//
// // Solid
// import {fas} from '@fortawesome/pro-solid-svg-icons';
//
// // Brands
// import {fab} from '@fortawesome/free-brands-svg-icons';
//
// library.add(fal, far, fad, fas, fab);

// or

// to subset a large number of icons into only the icons that you are using
// import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
// import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'
// library.add(faPhone, faEnvelope, faPaperPlane)


// automatically find any <i> tags in the page and replace those with <svg> elements
// https://fontawesome.com/how-to-use/with-the-api/methods/dom-i2svg
dom.i2svg()

// or

// if content is dynamic
// watch the DOM automatic for any additional icons being added or modified
// https://fontawesome.com/how-to-use/with-the-api/methods/dom-watch
dom.watch()
