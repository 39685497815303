export default class SearchTypeahead {

  constructor(selector) {
    $(selector).typeahead({
      minLength: 2,       // that's what we need to find stuff like "BP"
      maxItem: 20,      // this could be less for mobile
      maxItemPerGroup: 10,
      dynamic: true,    // search is done on the server. update on every keystroke
      delay: 300,     // default
      order: null,    // default. ordering is done by groups, inside the group on the server
      offset: false,
      accent: false,   // default. we will do this on the server side
      highlight: false,    // default
      multiselect: null,    // default
      cancelButton: false,
      asyncResults: true,    // update date as it comes in for every group (maybe switch to a combined result later for performance)
      filter: false,   // we search on the server and only use the lib to display and highlight the query (results)
      emptyTemplate: false,   // default
      hint: true,
      group: {
        template: function (item) {
          const groupMap = { // TODO i18n from server
            "instruments": "Aktien",
            "trends": "Trends",
            "synonyms": "Trendsynonyme",
            "fonds": "ETF",
            "analyses": "Analysen"
          };
          return '<span class="hit-group" data-group="' + item.group + '"><span class="group-title">' + groupMap[item.group]+ '</span><span class="group-title-hover">alle Ergebnisse anzeigen<i class="far fa-long-arrow-right"></i></span></span>';
        }
      },
      groupOrder: [
        "instruments",
        "trends",
        "fonds",
        "analyses"
      ],
      source: {
        instruments: {
          display: ["name", "isin"],
          href: "{{url}}",
          template: '<span class="hit-group-instruments"><span class="hit-title">{{name}}</span></span><i class="far fa-long-arrow-right"></i>',
          ajax: {
            type: "GET",
            url: config.xhrURLs.getSearchResults["instruments"],
            data: {
              q: "{{query}}"
            }
          }
        },
        trends: {
          display: ["name", "description"],
          href: "{{url}}",
          template: '<span class="hit-group-trends"><span class="hit-title">{{name}}</span></span><i class="far fa-long-arrow-right"></i>',
          ajax: {
            type: "GET",
            url: config.xhrURLs.getSearchResults["trends"],
            data: {
              q: "{{query}}"
            }
          }
        },
        fonds: {
          display: ["fonds"],
          href: "{{url}}",
          template: '<span class="hit-group-fonds"><span class="hit-title">{{name}}</span></span><i class="far fa-long-arrow-right"></i>',
          ajax: {
            type: "GET",
            url: config.xhrURLs.getSearchResults["fonds"],
            data: {
              q: "{{query}}"
            }
          }
        },
        analyses: {
          display: ["title", "description"],
          href: "{{url}}",
          template: '<span class="hit-group-analyses"><span class="hit-title">{{title}}</span></span><i class="far fa-long-arrow-right"></i>',
          ajax: {
            type: "GET",
            url: config.xhrURLs.getSearchResults["analyses"],
            data: {
              q: "{{query}}"
            }
          }
        }
      },
      callback: {
        onLayoutBuiltBefore: function(node, query, result, resultHtmlList) {
          if(resultHtmlList) return resultHtmlList.prepend(
            $('<li class="typeahead__item"><a href="#" class="form-submit">Alle Ergebnisse anzeigen<i class="far fa-long-arrow-right"></i></a></li>')
          )
        },
        onSubmit: function(node, form, item, event) {
          // On submit do not go to the static "all results" page but trigger the hinted result if there is one.
          const search = window.Typeahead['input']; // The documentation says the selector should be ".typeahead" but hey... who am i to judge.
          if (null!=search.hintIndex) {
            event.preventDefault();
            window.location.href = search.result[search.hintIndex].href;
          }
        },
        onShowLayout: function() {
          $('.header').addClass('search-open');
          $('body').addClass('typeahead-open typeahead-fade');
          setTimeout(function () {
            $('body').removeClass('typeahead-fade');
          }, 200)
        },
        onHideLayout: function() {
          $('body').addClass('typeahead-fade');
          setTimeout(function () {
            $('body').removeClass('typeahead-open typeahead-fade');
          }, 200)
        }
      }
    });

    // show all results = submit form
    $(document).on('click', '.form-submit', function (e) {
      e.preventDefault();
      const form = $(this).closest('form');
      form.find('input[name=filter]').val('all');
      form.get(0).submit();
    })

    // show all group results = submit form
    $(document).on('click', '.hit-group', function (e) {
      e.preventDefault();
      const form = $(this).closest('form');
      form.find('input[name=filter]').val($(this).data('group'));
      form.get(0).submit();
    })
  }
}
