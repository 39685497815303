export default class CookieDialog {

  constructor() {
    this.expiry = 365; // expiry days
    this.cookieName = 'cookiesAcceptedV2';
    this.init();
  }

  init() {
    const that = this;

    if(!that.getCookie(that.cookieName)) {
      $('#dialog-cookie').on('hide.bs.modal', function() {
        const cookieValue = $('#analytics-accept').is(':checked') ? 2 : 1;
        that.setCookie(that.cookieName, cookieValue, that.expiry);
        if (2===cookieValue) {
          try {
            gaTrack();
          }
          catch(e) {
            console.log('no gaTrack');
          }
        }
      }).modal('show');
    }
  }

  setCookie(key, value, expiry) { // expiry in days
    const expires = new Date();
    expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';path=/' + '; SameSite=Lax; Secure; expires=' + expires.toUTCString();
  }

  getCookie(key) {
    const keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
  }

  eraseCookie(key) {
    const keyValue = this.getCookie(key);
    this.setCookie(key, keyValue, '-1');
  }
}
