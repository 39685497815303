import Dygraph from "../vendor/dygraph";
import Utils from "./utils";
import '../vendor/rgraph/RGraph.common.core';
import '../vendor/rgraph/RGraph.common.dynamic';
import '../vendor/rgraph/RGraph.common.tooltips';
import '../vendor/rgraph/RGraph.bar';
import '../vendor/rgraph/RGraph.scatter';
import '../vendor/rgraph/RGraph.drawing.rect';
import '../vendor/rgraph/RGraph.drawing.xaxis';

// Colours depending on performance
import chartColors from "../../scss/themes/_js-colors.scss"
import utils from "./utils";

export default class Charts {

  constructor() {
    this.intersectConfig = {
      rootMargin: '0px 0px 50px 0px',
      threshold: 0
    };

    // Defaults for bubble and bar charts (RGraph lib)

    // RGraph sets "Arial, Verdana" as default font in the style attribute of labels.
    // I tried to use "font-family: inherit !important" to get the default page font but to no avail...
    const fontFamily = window.getComputedStyle( $('body')[0], null ).getPropertyValue('font-family');

    this.rgraphDefaultConfig = {
      // Layout
      marginTop: 30,
      marginRight: 20,
      marginBottom: 30,
      marginLeft: 20,

      // Axis
      textAccessible: true,
      xaxisColor: chartColors.gridLineColor,
      xaxisLabelsColor: chartColors.gridLineColor,
      xaxisLabelsSize: 10,
      yaxisLabelsSize: 10,
      xaxisLabelsBold: false,
      yaxisLabelsBold: false,
      yaxisColor: chartColors.gridLineColor,
      yaxisLabelsColor: chartColors.gridLineColor,
      xaxisLabelsFont: fontFamily,
      yaxisLabelsFont: fontFamily,
      xaxisLabelsOffsety: 1,
      yaxisLabelsOffsetx: -19,
      yaxisLabelsOffsety: 0,
      xaxis: false,// "-" marks on axis labels
      yaxis: false,
      yaxisScaleThousand: '.',
      yaxisScalePoint: ',',
      yaxisLabelsHalign: 'left',

      // Background and grid
      backgroundGridVlines: false, // show or no
      backgroundGridHlines: true,  // show or no
      backgroundGridBorder: false, // frame around graph (we do it with CSS because it would be styled like the grid ... dotted, dashed)
      backgroundGridDotted: true,
      backgroundGridLinewidth: 1.1,
      backgroundColor: chartColors.bgColourLight,
      backgroundGridColor: chartColors.gridLineColor
    }

    this.calculateYScaleMax = function(value) {
      const v = Math.ceil(value);
      return (0>=v) ? null : v; // If we returned 0 instead of 'null' the chart wouldn't draw the bars
    };

    this.ttId = "tl-rgraph-tooltip";
    this.tooltipNumberFormat = new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2});
    this.tooltipNumberFormatter = function(v) {
      return this.tooltipNumberFormat.format(v);
    }

    this.dMsc = 86400000;

    this.initResizeHandler();
    this.initObserver();
    this.initListeners();
    this.initTlSwitch();
    this.initTlSwitchList();
    this.initChartDropdowns();
  }

  // RGraph based charts are not responsive and need to be redrawn manually.
  initResizeHandler() {
    const that = this;
    let resizeTO = null;
    $(window).on('resize', function() {
      clearTimeout(resizeTO);
      resizeTO = setTimeout(function() {
        const registryCopy = RGraph.ObjectRegistry.objects.byCanvasID.map((x) => x);
        $.each(registryCopy, function(i, a) {
          if (!a) return;

          const canvas = a[1].canvas;
          const wrapper = $(canvas).parent().parent()[0];

          if (!wrapper) return;

          // Difference in dimensions of wrapper (minus border) and canvas signals a responsive change in layout.
          // We only check for changes in width because height change should not matter and in our current layout it is
          // also an unreliable and moving part.
          //console.log(i, wrapper.clientWidth, canvas.clientWidth);
          if (wrapper.clientWidth !== canvas.clientWidth) {
            const container = $(wrapper).parents('.chart').first();
            const chartData = container.data('chart-data');
            const chartType = container.data('chart-type');
            //RGraph.ObjectRegistry.objects.byCanvasID.splice(i, 1);
            RGraph.ObjectRegistry.objects.byCanvasID = RGraph.ObjectRegistry.objects.byCanvasID.filter(x => x !== a);
            that.initChart(container, chartData, chartType);
          }
        });

      }, 300)
    })
  }

  // init intersection observer for lazy loading charts
  initObserver() {
    const that = this;
    window.chartObserver = new IntersectionObserver(function (entries, self) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const $container = $(entry.target);
          const chartId = $container.data('chart-id');
          const chartType = $container.data('chart-type');
          that.getChartData($container, chartId, chartType);
          self.unobserve(entry.target);
        }
      });
    }, this.intersectConfig);

    $('.chart[data-chart-id]').each(function() {
      window.chartObserver.observe($(this).get(0));
    })
  }

  // listen to updates from outside
  initListeners() {
    const that = this;

    // chart-id was changed, so get new data and render
    $(document).on('chart:update', '.chart', function (e) {
      const $container = $(e.target);
      const chartId = $container.data('chart-id');
      const chartType = $container.data('chart-type');
      that.getChartData($container, chartId, chartType);
    })

    // chart had updates in container size, so re-render with same data
    $(document).on('chart:rerender', '.chart', function (e) {
      const $container = $(e.target);
      const initialRangeId = $container.data('tl') || 'tl1YAgo';
      that.drawPerformanceChart($container, $container.data('chartData'), initialRangeId);
    })

    // chart had updated TL, so just set new TL
    $(document).on('chart:updateTl', '.chart', function (e) {
      const $container = $(e.target);
      const initialRangeId = $container.data('tl') || 'tl1YAgo';
      $container.data('chart').updateDateWindow(initialRangeId);
    })
  }

  // init timeline switcher tabs
  initTlSwitch() {
    $(document).on('click', '.chart-switch-tl li a', function (e) {
      e.preventDefault();
      $(this).addClass('active').closest('li').siblings().find('a').removeClass('active');
      const dw = $(this).data('timeline');
      const $target = $($(this).closest('.tab-nav').data('target')) || false;
      if($target && $target.length) {
        $target.find('.chart').each(function () {
          const $container = $(this);
          $container.data('tl', dw); // store date window for not yet lazy loaded charts
          if ($container.data('chart')) {
            $container.data('chart').updateDateWindow(dw);
          }
        })
      }
      else {
        const $container = $(this).closest('.chart') || false;
        if ($container && $container.length && $container.data('chart')) {
          $container.data('chart').updateDateWindow(dw);
        }
      }
    })
  }

  // init timeline switcher dropdowns
  initTlSwitchList() {
    $(document).on('click', '.chartswitch a.dropdown-item', function (e) {
      e.preventDefault();
      const $list = $('#' + $(this).closest('.dropdown-menu').data('tl-target'));
      if (!$list.length) return;
      $(this).addClass('active').siblings('a').removeClass('active');
      $(this).closest('.chartswitch').find('.current').text($(this).text());
      const dw = $(this).data('timeline');
      $list.find('.chart').each(function () {
        const $container = $(this);
        $container.data('tl', dw); // store date window for not yet lazy loaded charts
        if ($container.data('chart')) {
          $container.data('chart').updateDateWindow(dw);
        }
      })
    })
  }

  // init chart toggle dropdowns
  initChartDropdowns() {
    const that = this;
    $(document).on('click', '.chart-dropdown a.dropdown-item', function () {
      const $container = $(this).closest('.chart');
      const chartType = $(this).data('chart-type');
      const entityId = $(this).data('chart-id');
      that.getChartData($container, entityId, chartType);
    })
  }

  // get chart data for $container
  getChartData($container, entityId, chartType) {
    const that = this;

    // check for chart data in global charts
    if(charts && charts[entityId]) {
      $container.data('chart-data', charts[entityId]); // Store data
      that.initChart($container, $container.data('chart-data'), chartType);
      return;
    }

    // if not in global charts, get the data
    $.ajax({
      type: 'GET',
      url: config.xhrURLs.getChart[chartType],
      data: {
        id: entityId,
        type: chartType
      },
      dataType: 'json',
      success: function (res) {
        if (res.status === 'success') {
          $container.data('chart-data', res.data); // Store data for redraw
          that.initChart($container, res.data, chartType);
        } else {
          Utils.notify(0, 'Fehler getChart');
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        Utils.notify(0, 'Fehler getChart: ' + textStatus);
        console.log(jqXHR, textStatus, errorThrown);
      }
    })
  }

  // draw chart into $container
  initChart($container, chartData, chartType) {
    // Responsiveness
    $container.find('.graph').html(''); // Clear children of graph container

    switch (chartType) {
      case 1: {
        const initialRangeId = $container.data('tl') || 'tl1YAgo';
        $container.data('chart', this.drawPerformanceChart($container, chartData, initialRangeId));
        break;
      }
      // Sometimes the container is rendered too late and we get the wrong size.
      // So we wait a little bit for RGraph based charts to render...
      case 2: {
        setTimeout(function() {
          this.drawBubbleChart($container, chartData);
        }.bind(this), 100);
        break;
      }
      case 3: {
        setTimeout(function() {
          this.drawGroupedBarChart($container, chartData);
        }.bind(this), 100);
        break;
      }
      case 4: {
        setTimeout(function() {
          this.drawCandlestickChart($container, chartData);
        }.bind(this), 100);
        break;
      }
      case 5: {
        setTimeout(function() {
          this.drawStackedBarChart($container, chartData);
        }.bind(this), 100);
        break;
      }
      case 6: {
        setTimeout(function() {
          this.drawBarChart($container, chartData);
        }.bind(this), 100);
        break;
      }
    }
  }

  // Helper to align timestamps up to the day level.
  // If we had a tick at 2020-08-11 and an annotation at say: 2020-08-11 02:18 the annotation would not get shown.
  cleanDateMillis(ms) {
    return Math.trunc(ms / this.dMsc)*this.dMsc;
  }

  // Convert plain data arrays to a CSV format Dygraph understands
  ticks2csv(data, type) {
    const prepData = ['Date,' + type];   // CSV header
    // We guarantee on the serverside that both arrays have the same length.
    const ticksArray = data.ticks.split(';');
    const datesArray = data.dates.split(';');

    for (let i = 0; i < ticksArray.length; i++) {
      const day = new Date(datesArray[i]).getTime();
      const line = day + ',' + ticksArray[i];
      prepData.push(line);
    }

    return prepData.join('\n');
  }

  // TODO tmp (old) solution until we have real dates in trend performance data
  ticks2csvOLD(startDate, data, type) {
    const prepData = ['Date,' + type];   // CSV header
    const dataArray = data.split(';');
    let day = startDate;

    for (let i = 0; i < dataArray.length; i++) {
      const line = this.cleanDateMillis(day) + ',' + dataArray[i];
      prepData.push(line);
      day += this.dMsc;
    }

    return prepData.join('\n');
  }

  // convert plain annotation data to dysgraph annotations
  data2Annotation(dataArray) {
    const that = this;
    let chartAnnotations = [];
    $.each(dataArray, function (i, annotation) {
      chartAnnotations.push({
        series: 'Performance',
        x: that.cleanDateMillis(i),
        // TODO: handle shortText and cssClass for different types
        shortText: annotation[0].type.substring(0, 1).toUpperCase(),
        text: JSON.stringify({ts: i, annotation}),
        cssClass: 'annotation-' + annotation[0].type + ' ' + annotation[0].data.outlook
      });
    });

    return chartAnnotations;
  }

  //
  // RGraph
  //



  data2Bubbles(data) {
    const d = {
      min: 0,
      max: 0,
      data: [],
      labels: [],
      weight: []
    };

    $.each(data, function(i, v) {
      // Labels
      d.labels.push(v.x);

      // Estimation
      d.data.push([
        v.x,                                                // X
        v.y[0],                                             // Y
        chartColors.estimation,                             // Colour
        ''                                                  // Tooltip
      ]);
      d.weight.push(34);                                    // The values of the bubbles (here: fixed diameter)

      // Reality
      d.data.push([
        v.x,                                                   // X
        v.y[1],                                                // Y
        (v.y[0]<v.y[1]) ? chartColors.pos : chartColors.neg,   // Colour
        ''                                                     // Tooltip
      ]);
      d.weight.push(34);                                       // The values of the bubbles (here: fixed diameter)

      d.max = Math.max(d.max, v.y[0], v.y[1]);
      d.min = Math.min(d.min, v.y[0], v.y[1]);
    });

    return d;
  }

  data2single(data) {
    const d = {
      min: 0,
      max: 0,
      data: [],
      labels: [],
      colors: []
    };

    $.each(data, function(i, v) {
      // Labels
      d.labels.push(v.x);

      // Colours
      const vy = isNaN(parseInt(v.y)) ? 0 : v.y;
      const c = (0>vy) ? chartColors.neg : chartColors.pos;
      d.colors.push(c);

      // Data
      d.data.push(v.y);
      d.max = Math.max(d.max, v.y);
      d.min = Math.min(d.min, v.y);
    });

    return d;
  }

  data2grouped(data) {
    const d = {
      min: 0,
      max: 0,
      data: [],
      labels: [],
      /*colors: [
        chartColors.estimation,
        chartColors.pos
      ]*/
      colors: []
    };

    $.each(data, function(i, v) {
      // Labels
      d.labels.push(v.x);

      // Colours
      const vy = isNaN(parseInt(v.y[1])) ? 0 : v.y[1];
      const c = (0>vy) ? chartColors.neg : chartColors.pos;
      d.colors.push(chartColors.estimation);
      d.colors.push(c);

      // Data
      d.data.push(v.y);
      d.max = Math.max(d.max, v.y[0], v.y[1]);
      d.min = Math.min(d.min, v.y[0], v.y[1]);
    });

    return d;
  }

  data2stacked(data) {
    const d = {
      min: 0,
      max: 0,
      data: [],
      labels: [],
      colors: [
        '#1D6E3A',
        '#2AB759',
        '#B98A2C',
        '#F25C5F'
      ]
    };

    $.each(data, function(i, v) {
      // Labels
      d.labels.push(v.x);

      // Data
      // Make sure no negatives values, otherwise rgraph will trigger an alert box :-(
      const pa = v.y.map(y => Math.max(y, 0));
      d.data.push(pa);
      d.max = Math.max(d.max, Math.max(...v.y));
    });

    return d;
  }

  data2candlesticks(data) {
    const d = {
      data1: [],
      data2: [],
      data3: [],
      min: 0,
      max: 0,
      labels: [],
      colors: [
        chartColors.pos,
        '#6a6a6a',
        '#007de8'
      ]
    };

    $.each(data, function(i, v) {
      // Labels
      d.labels.push(v.x);

      // Data
      d.data1.push(v.y[0]);    // Max
      d.data2.push(v.y[1]);    // Selected
      d.data3.push(v.y[2]);    // Compare

      d.max = Math.max(d.max, Math.max(...v.y));
    });

    return d;
  }

  // Draw Dygraph performance chart
  drawPerformanceChart($container, performanceObject, initialRangeId) {
    const that = this;
    const chartElement = $container.addClass('loaded').find('.graph').get(0);

    const createDates = function (tlTimestamp) {
      const tlDate = new Date(tlTimestamp);

      // Possible date ranges (beginning - now / eg.: tl1YAgo - tlTimestamp)
      const tl1MAgo = new Date(tlTimestamp);
      tl1MAgo.setMonth(tlDate.getUTCMonth() - 1);

      const tl1YAgo = new Date(tlTimestamp);
      tl1YAgo.setFullYear(tlDate.getUTCFullYear() - 1);

      const tl5YAgo = new Date(tlTimestamp);
      tl5YAgo.setFullYear(tlDate.getUTCFullYear() - 5);

      return {
        tlNow: tlTimestamp,
        tl1MAgo: tl1MAgo.getTime(),
        tl1YAgo: tl1YAgo.getTime(),
        tl5YAgo: tl5YAgo.getTime()
      };
    };

    const dates = createDates(performanceObject.date);

    const chartCsv = (performanceObject.hasOwnProperty('dates'))
      ? this.ticks2csv(performanceObject, 'Performance')
      : this.ticks2csvOLD(dates.tl5YAgo, performanceObject.ticks, 'Performance');

    const $xline = $('<div class="xline"></div>');

    const chartHelpers = {
      // Format legend
      dateFormatter: function (ts) {
        const d = new Date(ts);
        const dateStr = String(d.getDate()).padStart(2, "0") + '.' + String(d.getMonth() + 1).padStart(2, "0") + '.' + d.getFullYear();

        return `<span class="date">${dateStr}</span>`;
      },

      legendFormatter: function (data) {
        // On page load we get called without data, so let's grab the last performance value.
        let perf = (data.series[0].hasOwnProperty('y'))
          ? data.series[0].y
          : data.dygraph.rawData_[data.dygraph.rawData_.length - 1][1]||0;// ||0 is just for avoiding js errors with instruments that have no performance at all

        // Show max 2 digits before the decimal point.. above, we round
        if (99 < perf) {
          perf = Math.round(perf);
        }
        // show max 2 digits after the decimal point to save some space and format for the current locale.
        else {
          perf = this.performanceFormatter(perf);
        }

        return `<span class="p">${perf}</span>`;
      },

      legendFormatterSlider: function () {
        // TREN-334 / TREN-336 pinpointed to the 1y performance
        const colourCss = (0>performanceObject.performance.p1yPerc) ? "neg" : "pos";
        return `<div class="marker ${colourCss}"></div><div class="title">${performanceObject.title}</div><div class="perf ${colourCss}"><span class="p">${performanceObject.performance.p1yPerc}</span><small>%</small></div>`;
      },

      legendFormatterInteractive: function (data) {
        const d = data.x
          ? chartHelpers.dateFormatter(data.x)
          : chartHelpers.dateFormatter(data.dygraph.rawData_[data.dygraph.rawData_.length - 1][0]);

        return d + chartHelpers.legendFormatter(data);
      },

      axisLabelFormatter: function (x, granularity) {
        const d = new Date(x);
        // German months
        const gMonth = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']

        // See "Granularity" enum in dygraph.js
        //   22 -> Weekly
        //   24 -> Quarterly
        //   26 -> Annual
        // For "Weekly" we display day of month and month name,
        // for the rest month and year
        if (22 === granularity) {
          return d.getDate() + ' ' + gMonth[d.getMonth()];
        } else {
          return gMonth[d.getMonth()] + ' ' + d.getFullYear().toString().substr(-2);
        }
      },

      // Here we do i18n when needed.
      performanceFormatter: function (data) {
        return Number.parseFloat(data).toFixed(2).toString().replace('.', ',');
      },

      // draw line at current cursor position
      highlightCallback: function (e, x, pts) {
        $xline.show().css({'left': pts[0].canvasx + 'px'});
      },

      // remove cursor line
      unhighlightCallback: function () {
        $xline.hide();
      },

      zoomCallback: function (min, max) {
        const minDate = chart.rawData_[0][0];
        const maxDate = chart.rawData_[chart.rawData_.length - 1][0];
        const zoomOutTotal = (minDate === min && maxDate === max);

        // reset to default selection when zooming out (doubleclick et.al. on canvas)
        if (zoomOutTotal) {
          $container.find('.chart-switch-tl a[data-timeline="' + initialRangeId + '"]').click();
        }
        // disable active timeline tab when zoomed
        else {
          $container.find('.chart-switch-tl a').removeClass('active');
        }
      },

      drawCallback: function (dg, isInitial) {
        if (dg.annotations_.length) {
          chartHelpers.initAnnotationPopovers($(dg.graphDiv));
        }
        if(isInitial) $container.trigger('chart:drawn');
        else $container.trigger('chart:redrawn');
      },

      initAnnotationPopovers: function ($chartElement) {
        $chartElement.find('.annotation-analysis').each(function () {
          const anns = JSON.parse($(this).attr('title'));
          $(this).attr('title', '');

          const annDate = chartHelpers.dateFormatter(parseInt(anns.ts));
          const aSrc = [`<div class="date">${annDate}</div>`];
          // currently unused //const aType  = annData.type;
          // currently unused //const aColor = chartColors[annData.data.outlook];

          $.each(anns.annotation, function (i, ann) {
            aSrc.push(`
              <div class="item">
                <div class="author">Analyse von <a href="${ann.data.userLink}">${ann.data.user}</a></div>
                <a href="${ann.data.link}">${ann.data.title}</a>
              </div>
            `);
          });

          $(this).popover({
            template: '<div class="popover chart-annotation" role="tooltip"><div class="arrow"><div class="hover-catch"></div></div><div class="popover-body"></div></div>',
            trigger: 'manual',
            html: true,
            content: aSrc.join(''),
            placement: 'top',
            animation: false
          }).on('mouseenter', function () {
            const _this = this;
            $(this).popover('show');
            $('.popover.chart-annotation').on('mouseleave', function () {
              $(_this).popover('hide');
            });
          });

        })
      }
    };

    // Dygraph options
    const getOptions = function (chartSize) {
      const config = {
        baseConfig: {
          legend: 'always',
          fillGraph: true,
          strokeWidth: 1.8,
          axisLineColor: chartColors.axisLineColor, // aka strokeStyle
          drawAxesAtZero: false,
          connectSeparatedPoints: true,
          digitsAfterDecimal: 2,
          pixelRatio: 1, // disable pixel ratio dependent rendering
          drawCallback: chartHelpers.drawCallback,
          axes: {
            x: {
              gridLineColor: chartColors.gridLineColor,
              gridLineWidth: .8,
              gridLinePattern: [1, 5],
              ticker: Dygraph.dateTicker,
              axisLabelFormatter: chartHelpers.axisLabelFormatter
            },
            y: {
              gridLineColor: chartColors.gridLineColor,
              gridLineWidth: .8,
              gridLinePattern: [1, 5],
              axisLabelWidth: 15
            }
          },
          xValueParser: function (x) {
            return parseInt(x);
          }
        },
        small: {
          interactionModel: {},
          strokeWidth: 1,
          rightGap: 0,
          labelsDiv: $container.parent().find('.data').get(0),
          highlightCircleSize: 0,
          legendFormatter: chartHelpers.legendFormatterSlider,
          drawAxis: false,
          drawGrid: false
        },
        medium: {
          interactionModel: {},
          title: ' ', // set empty title to save some space above graph
          titleHeight: 20,
          rightGap: 20,
          labelsDiv: $container.find('.chart-legend').get(0),
          hideOverlayOnMouseOut: false,
          xlabel: ' ', // set empty xlabel to save some space below graph
          xLabelHeight: 5,
          legendFormatter: chartHelpers.legendFormatterInteractive,
          highlightCallback: chartHelpers.highlightCallback,
          unhighlightCallback: chartHelpers.unhighlightCallback
        },
        large: {
          title: ' ', // set empty title to save some space above graph
          titleHeight: 20,
          rightGap: 20,
          labelsDiv: $container.find('.chart-legend').get(0),
          hideOverlayOnMouseOut: false,
          xlabel: ' ', // set empty xlabel to save some space below graph
          xLabelHeight: 10,
          legendFormatter: chartHelpers.legendFormatterInteractive,
          highlightCallback: chartHelpers.highlightCallback,
          unhighlightCallback: chartHelpers.unhighlightCallback,
          zoomCallback: chartHelpers.zoomCallback
        }
      };

      return $.extend(config.baseConfig, config[chartSize] || {});
    };

    // get the chart size by container class
    let chartSize = 'small';
    const chartSizes = $container.attr('class').match(/(?:^|\s)chart-size-([^- ]+)(?:\s|$)/);
    if (chartSizes && chartSizes.length) {
      chartSize = chartSizes[1];
    }

    const getPerformance = function(rangeId) {
      return (function (id) {
        switch (id) {
          case 'tl1MAgo':
            return performanceObject.performance.p1mPerc;
          case 'tl1YAgo':
            return performanceObject.performance.p1yPerc;
          case 'tl5YAgo':
            return performanceObject.performance.p5yPerc;
          default:
            return 0;
        }
      })(rangeId);
    };

    const getChangeStyle = function (rangeId) {
      const perf = getPerformance(rangeId);
      if (0<perf) return "pos";
      if (0>perf) return "neg";
      return "uc";
    };

    // set options..
    const chartOptions = $.extend(
      // .. from data
      {
        color: chartColors[getChangeStyle(initialRangeId)],
        dateWindow: [dates[initialRangeId], dates.tlNow]
      },
      // .. depending on size
      getOptions(chartSize)
    );

    // draw the graph
    const chart = new Dygraph(chartElement, chartCsv, chartOptions);
    chart.ready(function () {
      if (chartSize !== 'small') {
        // set annotations
        const annotations = that.data2Annotation(performanceObject.annotations);
        chart.setAnnotations(annotations);
        // handle visibility of ticks label
        $(chart.getOption('labelsDiv')).hide();
        $($(chartElement).find('canvas')).hover(
          function () {
            $(chart.getOption('labelsDiv')).show();
          },
          function () {
            $(chart.getOption('labelsDiv')).hide();
          }
        );
      }
    });

    // allow changes to the date window from the outside
    chart.updateDateWindow = function (rangeId) {
      const change = getChangeStyle(rangeId);

      // update performance label for selected range
      this.updatePerformanceLabel(rangeId, change);

      // update graph
      const color = chartColors[change];
      this.updateOptions({
        color: color,
        dateWindow: [dates[rangeId], dates.tlNow]
      })
    };

    chart.updatePerformanceLabel = function (rangeId) {
      const perf = getPerformance(rangeId);
      const change = getChangeStyle(rangeId);
      const perfLabelEl = $(chartElement).siblings('.chart-perf')[0];
      $(perfLabelEl).html(`<span class="p ${change}">${chartHelpers.performanceFormatter(perf)}<small>%</small></span>`);
    };

    $(chartElement).prepend($xline);

    // set title
    if ($container.find('.chart-title').length) {
      $container.find('.chart-title').text(performanceObject.title);
    }

    // Initial performance value in label
    chart.updatePerformanceLabel(initialRangeId);

    return chart;
  }

  // https://www.rgraph.net/canvas/scatter.html
  drawBubbleChart($container, chartData) {
    const data = this.data2Bubbles(chartData);

    // Generate a unique id to relate the canvas-tag to the script.
    const canvasId = utils.generateUniqueId('bc-');
    const $graph = $container.find('.graph');
    if (0<data.data.length) {
      $graph.html(`<canvas id="${canvasId}" width="${$graph.width()}" height="${$graph.height()}"></canvas>`);
    } else {
      $graph.html(`<div role="alert" class="alert alert-dark">Keine Daten verfügbar</div>`);
      return;
    }

    const charts = this;
    function ttRenderer(obj, text, x, y, idx) { //tooltipsOverride: ttRenderer,
      $('#'+charts.ttId).remove();
      //console.log([obj, idx, data]);
      const v = charts.tooltipNumberFormatter(data.data[idx][1]);
      const ttHtml = '<div id="'+charts.ttId+'">'+v+'</div>';
      const ttEl = $.parseHTML(ttHtml)[0];
      $(ttEl).offset({top: Math.max(obj.coordsBubble[0][idx][1]-40, 0), left: Math.max(obj.coordsBubble[0][idx][0]-8, 0)});
      obj.canvas.insertAdjacentElement('afterend', ttEl);
    }

    const g = new RGraph.Scatter({
      id: canvasId,
      data: data.data,
      options: $.extend({}, this.rgraphDefaultConfig, {
        // Labels
        xaxisLabels: data.labels,
        yaxisScaleMin: (0===data.min) ? 0 : data.min - 1, // Draw negative values
        yaxisScaleMax: this.calculateYScaleMax(data.max), // We need to set this explicitly because there seems to be a bug in calculating the max if data contains decimal points

        // Scatter plot specific. The -1/+1 is just to improve display with 1 year margin left and right.
        xaxisScaleMin: data.labels[0]-1,
        xaxisScaleMax: data.labels[data.labels.length-1]+1,

        // Bubble chart specific
        colorsBubbleGraduated: false,                    // no 3D bubbles
        bubbleMin: 0,                                    // the minimum for the bubble values
        bubbleMax: 100,                                  // the maximum for the bubble values
        bubbleWidth: 34,                                 // the maximum width of the bubbles
        bubbleData: data.weight,                         // the values of the bubbles (diameter)

        // Mouseover show value
        tooltipsOverride: ttRenderer,
        tooltipsEvent: 'mousemove', // default: 'click'
        tooltipsEffect: 'none',
        tooltips: '%{value}' // needed, otherwise tootipsOverride will not get called :-(
      })
    });

    // Overriding method for custom circles (estimation)
    g.drawBubble = function (dataset) {
      var data  = RGraph.isArray(g.properties.bubbleData) && RGraph.isArray(g.properties.bubbleData[dataset]) ? g.properties.bubbleData[dataset] : g.properties.bubbleData;
      var min   = RGraph.isArray(g.properties.bubbleMin) ? g.properties.bubbleMin[dataset] : g.properties.bubbleMin;
      var max   = RGraph.isArray(g.properties.bubbleMax) ? g.properties.bubbleMax[dataset] : g.properties.bubbleMax;
      var width = RGraph.isArray(g.properties.bubbleWidth) ? g.properties.bubbleWidth[dataset] : g.properties.bubbleWidth;

      // Initialise the coordinates array
      g.coordsBubble[dataset] = [];

      // Loop through all the points (first dataset)
      for (var i=0; i<this.coords[dataset].length; ++i) {
        if (null===g.coords[dataset][i][1]) continue; // no data, no bubble
        data[i] = Math.max(data[i], min);
        data[i] = Math.min(data[i], max);

        var radius = (((data[i] - min) / (max - min) ) * width) / 2,
          color  = g.data[dataset][i][2] ? g.data[dataset][i][2] : g.properties.colorsDefault;

        var fillColor = (chartColors.estimation===color) ? chartColors.bgColorDark : color;

        g.context.beginPath();
        g.context.fillStyle = RGraph.radialGradient({
          object: g,
          x1:     g.coords[dataset][i][0] + (radius / 2.5),
          y1:     g.coords[dataset][i][1] - (radius / 2.5),
          r1:     0,
          x2:     g.coords[dataset][i][0] + (radius / 2.5),
          y2:     g.coords[dataset][i][1] - (radius / 2.5),
          r2:     radius,
          colors: [
            g.properties.colorsBubbleGraduated ? 'white' : fillColor,
            fillColor
          ]
        });

        // Draw the bubble
        g.context.arc(
          g.coords[dataset][i][0],
          g.coords[dataset][i][1],
          radius,
          0,
          RGraph.TWOPI,
          false
        );

        // Fill circle
        g.context.fill();

        // Draw circle border
        g.context.strokeStyle = color;
        g.context.stroke();

        g.coordsBubble[dataset][i] = [
          g.coords[dataset][i][0],
          g.coords[dataset][i][1],
          radius,
          g.context.fillStyle
        ];
      }
    };

    g.draw();

    // Draw solid x-axis
    new RGraph.Drawing.XAxis({
      id: canvasId,
      y: g.getYCoord(0),
      options: {
        xaxisTickmarks: false,
        marginLeft: 20,
        marginRight: 20,
        xaxisColor: chartColors.chartBorderColor
      }
    }).draw();

    return g;
  }

  // https://www.rgraph.net/canvas/bar.html
  drawBarChart($container, chartData) {
    const data = this.data2single(chartData);

    // Generate a unique id to relate the canvas-tag to the script.
    const canvasId = utils.generateUniqueId('bc-');
    const $graph = $container.find('.graph');
    if (0<data.data.length) {
      $graph.html(`<canvas id="${canvasId}" width="${$graph.width()}" height="${$graph.height()}"></canvas>`);
    } else {
      $graph.html(`<div role="alert" class="alert alert-dark">Keine Daten verfügbar</div>`);
      return;
    }

    // Compute distance between bars to force a certain width for bars.
    // ... there is no way to define bar width absolutely as a property.
    const colCnt = data.data.length;
    const barWidth = 18;
    const marginInnerCmp = ((($graph.width() - (colCnt * barWidth)) / colCnt) / 2);

    const options = $.extend({}, this.rgraphDefaultConfig, {
      // Labels
      xaxisLabels: data.labels,

      // Colours
      colorsSequential: true, // Otherwise we cannot draw negative values in a different colour

      // Bar chart specific
      colors: data.colors,
      marginInner: marginInnerCmp
    });

    // Seems like display of negative values got bolted on afterwards. So we have to come up with some strange ways to render beautifully.
    if (0>data.min) {
      options.yaxisScaleMin = Math.floor(data.min); // Allow drawing of negative values
      if (0===data.max) {
        // If we set it to '0', the bars won't get drawn..
        // If we set it to 'null', the positive scale would just be a mirror of the negative.
        // ... honestly, how bad can software be?
        options.yaxisScaleMax = 1;
      }
    }

    const charts = this;
    function ttRenderer(obj, text, x, y, idx) {
      $('#'+charts.ttId).remove();
      const v = charts.tooltipNumberFormatter(data.data[idx]);
      const ttHtml = '<div id="'+charts.ttId+'">'+v+'</div>';
      const ttEl = $.parseHTML(ttHtml)[0];
      $(ttEl).offset({top: Math.max(obj.coords[idx][1]-50, 0), left: Math.max(obj.coords[idx][0]-20, 0)});
      obj.canvas.insertAdjacentElement('afterend', ttEl);
    }
    const g = new RGraph.Bar({
      id: canvasId,
      data: data.data,
      options: $.extend(options, this.rgraphDefaultConfig, {
        // Mouseover show value
        tooltipsOverride: ttRenderer,
        tooltipsEvent: 'mousemove', // default: 'click'
        tooltipsEffect: 'none',
        tooltips: '%{value}' // needed, otherwise tootipsOverride will not get called :-(
      })
    });

    g.draw();

    new RGraph.Drawing.XAxis({
      id: canvasId,
      y: g.getYCoord(0),
      options: {
        xaxisTickmarks: false,
        marginLeft: 20,
        marginRight: 20,
        xaxisColor: chartColors.chartBorderColor
      }
    }).draw();

    return g;
  }

  // https://www.rgraph.net/canvas/bar.html
  drawGroupedBarChart($container, chartData) {
    const data = this.data2grouped(chartData);

    // Generate a unique id to relate the canvas-tag to the script.
    const canvasId = utils.generateUniqueId('bc-');
    const $graph = $container.find('.graph');
    if (0<data.data.length) {
      $graph.html(`<canvas id="${canvasId}" width="${$graph.width()}" height="${$graph.height()}"></canvas>`);
    } else {
      $graph.html(`<div role="alert" class="alert alert-dark">Keine Daten verfügbar</div>`);
      return;
    }

    // Compute distance between bars to force a certain width for bars.
    // ... there is no way to define bar width absolutely as a property.
    const marginInnerGrouped = 3;
    const groupCnt = data.data.length;
    const memberCnt = data.data[0].length;
    const barWidth = 18;
    const groupWidth = (memberCnt * barWidth) + ((memberCnt - 1) * marginInnerGrouped);
    const marginInnerCmp = ((($graph.width() - (groupCnt * groupWidth)) / groupCnt) / 2);

    // Calculate how many y-labels we show
    const yMin = (0===data.min) ? 0 : Math.floor(data.min); // Draw negative values
    const yMax = this.calculateYScaleMax(data.max); // We need to set this explicitly because there seems to be a bug in calculating the max if data contains decimal points
    const yCnt = Math.min(5, Math.max(0, yMax) + Math.abs(yMin));

    const charts = this;
    function ttRenderer(obj, text, x, y, idx) { //tooltipsOverride: ttRenderer,
      $('#'+charts.ttId).remove();
      const v = charts.tooltipNumberFormatter(data.data.flat()[idx]);
      const ttHtml = '<div id="'+charts.ttId+'">'+v+'</div>';
      const ttEl = $.parseHTML(ttHtml)[0];
      $(ttEl).offset({top: Math.max(obj.coords[idx][1]-50, 0), left: Math.max(obj.coords[idx][0]-20, 0)});
      obj.canvas.insertAdjacentElement('afterend', ttEl);
    }

    // Bars
    const g = new RGraph.Bar({
      id: canvasId,
      data: data.data,
      options: $.extend({}, this.rgraphDefaultConfig, {
        // Labels
        xaxisLabels: data.labels,
        yaxisLabelsCount: yCnt,
        yaxisScaleMin: yMin,
        yaxisScaleMax: yMax,

        // Colours
        colorsSequential: true, // Otherwise we cannot draw negative values in a different colour

        // Bar chart specific
        colors: data.colors,
        marginInner: marginInnerCmp,

        // Grouping
        grouping: 'grouped',
        marginInnerGrouped: marginInnerGrouped, // Distance between bars in group

        // Mouseover show value
        tooltipsOverride: ttRenderer,
        tooltipsEvent: 'mousemove', // default: 'click'
        tooltipsEffect: 'none',
        tooltips: '%{value}' // needed, otherwise tootipsOverride will not get called :-(
      })
    });

    g.draw();

    new RGraph.Drawing.XAxis({
      id: canvasId,
      y: g.getYCoord(0),
      options: {
        xaxisTickmarks: false,
        marginLeft: 20,
        marginRight: 20,
        xaxisColor: chartColors.chartBorderColor
      }
    }).draw();

    return g;
  }

  drawStackedBarChart($container, chartData) {
    const that = this;
    const data = this.data2stacked(chartData);

    // Generate a unique id to relate the canvas-tag to the script.
    const canvasId = utils.generateUniqueId('bc-');
    const $graph = $container.find('.graph');
    if (0<data.data.length) {
      $graph.html(`<canvas id="${canvasId}" width="${$graph.width()}" height="${$graph.height()}"></canvas>`);
    } else {
      $graph.html(`<div role="alert" class="alert alert-dark">Keine Daten verfügbar</div>`);
      return;
    }

    // Compute distance between bars to force a certain width for bars.
    // ... there is no way to define bar width absolutely as a property.
    const groupCnt = data.data.length;
    const barWidth = 18;
    const marginInnerCmp = ((($graph.width() - (groupCnt * barWidth)) / groupCnt) / 2);

    // Calculate how many y-labels we show
    const yMin = 0; // Stacked charts cannot draw negative values
    const yMax = this.calculateYScaleMax(data.max); // We need to set this explicitly because there seems to be a bug in calculating the max if data contains decimal points
    const yCnt = Math.min(5, Math.max(0, yMax) + Math.abs(yMin));

    // Bars
    const bg = new RGraph.Bar({
      id: canvasId,
      data: data.data,
      options: $.extend({}, this.rgraphDefaultConfig, {
        // Labels
        xaxisLabels: data.labels,
        yaxisLabelsCount: yCnt,
        yaxisScaleMin: yMin,
        yaxisScaleMax: yMax, // We need to set this explicitly because there seems to be a bug in calculating the max if data contains decimal points

        // Stacked bar chart specific
        colors: data.colors,
        marginInner: marginInnerCmp,
        grouping: 'stacked'
      })
    }).on('draw', function (obj) { // The stacking part
      for (let i=0; i<obj.coords.length; ++i) {

        // These are the coordinates of the bar
        const x = obj.coords[i][0];
        const y = obj.coords[i][1];
        const width = obj.coords[i][2];
        const height = obj.coords[i][3];

        obj.context.fillStyle = '#ffffff';

        const txt = RGraph.text({
          object: obj,
          font:   that.rgraphDefaultConfig.xaxisLabelsFont,
          size:   10,
          x:      x + (width/ 2),
          y:      y + (height / 2),
          text:   obj.data_arr[i].toString(),
          valign: 'center',
          halign: 'center'
        });

        txt.node.style.textShadow = '-1px -1px 1px #000000, 1px 1px 1px #000000';
      }
    }).draw();

    new RGraph.Drawing.XAxis({
      id: canvasId,
      y: bg.getYCoord(0),
      options: {
        xaxisTickmarks: false,
        marginLeft: 20,
        marginRight: 20,
        xaxisColor: chartColors.chartBorderColor
      }
    }).draw();

    return bg;
  }

  // https://www.rgraph.net/canvas/bar.html
  drawCandlestickChart($container, chartData) {
    const data = this.data2candlesticks(chartData);

    // Generate a unique id to relate the canvas-tag to the script.
    const canvasId = utils.generateUniqueId('bc-');
    const $graph = $container.find('.graph');
    if (!isNaN(data.data1.reduce((a, b) => parseInt(a)+parseInt(b)))) {
      $graph.html(`<canvas id="${canvasId}" width="${$graph.width()}" height="${$graph.height()}"></canvas>`);
    } else {
      $graph.html(`<div role="alert" class="alert alert-dark">Keine Daten verfügbar</div>`);
      return;
    }

    // Compute distance between bars to force a certain width for bars.
    // ... there is no way to define bar width absolutely as a property.
    const groupCnt = data.data1.length;
    const barWidth = 18;
    const marginInnerCmp = ((($graph.width() - (groupCnt * barWidth)) / groupCnt) / 2);

    // Calculate how many y-labels we show
    const yMin = 0; // Do not draw negative values, even if we have them
    const yMax = this.calculateYScaleMax(data.max); // We need to set this explicitly because there seems to be a bug in calculating the max if data contains decimal points
    const yCnt = Math.min(5, Math.max(0, yMax));

    const charts = this;
    function ttRenderer(obj, text, x, y, idx) {
      $('#'+charts.ttId).remove();
      const v1 = charts.tooltipNumberFormatter(data.data1[idx]);
      const v2 = charts.tooltipNumberFormatter(data.data2[idx]);
      const ttHtml = '<div id="'+charts.ttId+'">Umsatz: '+v1+'<br/>Sch&auml;tzung: '+v2+'</div>';
      const ttEl = $.parseHTML(ttHtml)[0];
      $(ttEl).offset({top: Math.max(obj.coords[idx][1]-50, 0), left: Math.max(obj.coords[idx][0]-20, 0)});
      obj.canvas.insertAdjacentElement('afterend', ttEl);
    }

    // Bars
    const bg = new RGraph.Bar({
      id: canvasId,
      data: data.data1,
      options: $.extend({}, this.rgraphDefaultConfig, {
        // Labels
        xaxisLabels: data.labels,
        yaxisLabelsCount: yCnt,
        yaxisScaleMin: yMin,
        yaxisScaleMax: yMax,

        // Bar chart specific
        colors: data.colors,
        marginInner: marginInnerCmp,

        // Mouseover show value
        tooltipsOverride: ttRenderer,
        tooltipsEvent: 'mousemove', // default: 'click'
        tooltipsEffect: 'none',
        tooltips: '%{value}' // needed, otherwise tootipsOverride will not get called :-(
      })
    });

    bg.draw();

    // "Candles"
    const yMultiplier = bg.halfgrapharea / bg.scale2.max;
    $.each(data.data2, function (i, vO) {
      const v = vO * 2;  // Whatever... maybe depending on canvas dpi?

      // 1st value
      new RGraph.Drawing.Rect({
        id: canvasId,
        x: bg.coords[i][0] - 7,
        y: bg.grapharea + bg.marginTop - (yMultiplier * v),
        width: 21,
        height: 6,
        options: {
          colorsFill: data.colors[1]
        }
      }).draw();
    });

    $.each(data.data3, function (i, vO) {
      const v = vO * 2;  // Whatever... maybe depending on canvas dpi?

      // 2nd value
      new RGraph.Drawing.Rect({
        id: canvasId,
        x: bg.coords[i][0] - 7,
        y: bg.grapharea + bg.marginTop - (yMultiplier * v),
        width: 21,
        height: 6,
        options: {
          colorsFill: data.colors[2]
        }
      }).draw();

    });

    new RGraph.Drawing.XAxis({
      id: canvasId,
      y: bg.getYCoord(0),
      options: {
        xaxisTickmarks: false,
        marginLeft: 20,
        marginRight: 20,
        xaxisColor: chartColors.chartBorderColor
      }
    }).draw();

    return bg;
  }

}
