import Utils from "./utils";

export default class InlineEditor {

  constructor($editContainer) {
    this.$editContainer = $editContainer;
    this.$buttonArea = $(`#${$editContainer.data('edit-buttons')}`);
    if(!this.$buttonArea.length) return;
    this.redactor = null;

    this.editingContent = '';
    this.editorId = Utils.generateUniqueId('inline-editor-');
    this.context = $editContainer.data('context');

    this.buttonEdit = this.$buttonArea.find('.edit');
    this.buttonSave = this.$buttonArea.find('.save');
    this.buttonCancel = this.$buttonArea.find('.cancel');

    this.initActions();
  }

  // UI interactions
  initActions() {
    const that = this;

    // bearbeiten
    this.buttonEdit.on('click', function (e) {
      e.preventDefault();
      that.edit();
    })

    // speichen
    this.buttonSave.on('click', function (e) {
      e.preventDefault();
      that.save();
    })

    // abbrechen
    this.buttonCancel.on('click', function (e) {
      e.preventDefault();
      that.cancel();
    })
  }

  // edit
  edit() {
    const container = this.$editContainer;
    const editor = $('<textarea class="inline-editor" id="'+ this.editorId +'" name="content"></textarea>');
    this.editingContent = container.html();
    this.buttonEdit.hide();
    this.buttonSave.show();
    this.buttonCancel.show();
    container.empty().append(editor);
    this.redactor = $R(`#${this.editorId}`, config.redactor.inlineEditor);
    this.redactor.source.setCode(this.editingContent);
    if(container.closest('.page-userprofile').length) $(this.redactor.container.getElement().nodes[0]).addClass('redactor-light');
    $(document).trigger('keepalive:start');
  }

  // cancel edit
  cancel() {
    $R(`#${this.editorId}`, 'destroy');
    this.redactor = null;
    this.$editContainer.html(this.editingContent);
    this.buttonEdit.show();
    this.buttonSave.hide();
    this.buttonCancel.hide();
    $(document).trigger('keepalive:end');
  }

  // save
  save() {
    const that = this;
    const data = {
      context: this.context,
      formData: {
        content: this.redactor.source.getCode()
      }
    }
    $.ajax({
      type: 'POST',
      url: config.xhrURLs.postInlineEditor,
      data: JSON.stringify(data),
      contentType: 'application/json',
      dataType: 'json',
      success: function (res) {
        if (res.status === 'success') {
          $R(`#${that.editorId}`, 'destroy');
          that.redactor = null;
          that.$editContainer.html(res.data.content);
          that.buttonEdit.show();
          that.buttonSave.hide();
          that.buttonCancel.hide();
          Utils.notify(3, config.successMessages.inlineEditorSaved);
        } else {
          Utils.notify(0, 'Fehler postUserAbout');
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        Utils.notify(0, 'Fehler postUserAbout: ' + textStatus);
        console.log(jqXHR, textStatus, errorThrown);
      },
      complete: function() {
        $(document).trigger('keepalive:end');
      }
    })
  }
}
