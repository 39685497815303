export default class TrendList {

  constructor() {
    this.$list = $('.trend-list ul');
    this.currentTl = '1y';
    this.filterTO = null;
    this.currentSort = {
      column: 'name',
      order: 'asc'
    }
    this.init();
  }

  init() {
    const that = this;

    // init timeline switch
    $(document).on('click', '.tab-nav li a', function (e) {
      e.preventDefault();
      $(this).addClass('active').closest('li').siblings().find('a').removeClass('active');
      that.currentTl = $(this).data('timeline');
      that.$list.removeClass('show-tl1m show-tl1y show-tl5y').addClass('show-tl' + that.currentTl);
      $('.sortables .tl').removeClass('asc desc').text($(this).text()).trigger('click');
    })

    // init sort
    $(document).on('click', '.sortables .sortable', function (e) {
      e.preventDefault();
      const order = $(this).hasClass('desc') ? 'asc' : 'desc';
      $(this).parent().find('.sortable').removeClass('asc desc');
      $(this).addClass(order);
      that.currentSort = {
        column: $(this).data('column'),
        order: order
      }
      that.sort();
    })

    // init filter
    $(document).on('keyup', '.search-input input', function () {
      const search = $(this).val().toLowerCase();
      clearTimeout(that.filterTO);
      that.filterTO = setTimeout(function() {
        that.filter(search);
      }, 500)
    })
    $(document).on('search', '.search-input input', function () {
      const search = $(this).val().toLowerCase();
      that.filter(search);
    })
  }

  sort() {
    const that = this;
    this.$list.find('li').sort(function (a, b) {
      // Assuming 0 if cell value is garbage
      const parseFloatRelaxed = function(el) {
        const raw = parseFloat(el.text().replace(',', '.'))
        return isNaN(raw) ? 0 : raw;
      };
      // numeric
      if (that.currentSort.column === 'tl') {
        if (that.currentSort.order === 'asc') {
          return parseFloatRelaxed($(`.tl${that.currentTl}`, a)) - parseFloatRelaxed($(`.tl${that.currentTl}`, b));
        } else {
          return parseFloatRelaxed($(`.tl${that.currentTl}`, b)) - parseFloatRelaxed($(`.tl${that.currentTl}`, a));
        }
      }
      // alphabetic
      else {
        if (that.currentSort.order === 'asc') {
          return $('a', a).text().localeCompare($('a', b).text());
        } else {
          return $('a', b).text().localeCompare($('a', a).text());
        }
      }
    }).appendTo(that.$list);
  }

  filter(search) {
    this.$list.find('li').each(function() {
      if(search && $(this).text().toLowerCase().indexOf(search) === -1) {
        $(this).hide();
      }
      else {
        $(this).show();
      }
    })
  }
}
