// Vendors
import './vendor/jquery';
import './vendor/bootstrap';
import './vendor/fontawesome';
import './vendor/toast';
import 'bootstrap-autocomplete';
import 'bootstrap-table/dist/bootstrap-table.js';
import 'bootstrap-table/dist/locale/bootstrap-table-de-DE.js';
import 'jquery-typeahead';

// TrendLink
import SocialShare from './lib/socialShare';
import Slider from './lib/slider';
import Charts from './lib/charts';
import Tables from './lib/tables';
import TrendList from './lib/trendList';
import InlineEditor from './lib/inlineEditor';
import PageFond from './lib/pageFond';
import SearchTypeahead from './lib/searchTypeahead';
import CookieDialog from './lib/cookieDialog';
import Synonyms from './lib/synonyms';

window.chartObserver = null;

// TREN-455 suppress RGraph alert boxes on bad data
window.alert = function() {};

$(function () {

  // --------------- Global ---------------
  new Charts;
  new SocialShare;
  new Tables;
  new CookieDialog;
  new SearchTypeahead('.typeahead');
  new Synonyms;

  // sliders
  $('.slider:not(.initialized)').each(function () {
    new Slider($(this));
  });

  $(document).on('slider:update', '.slider', function (e) {
    new Slider($(e.target));
  })

  // search
  $(document).on('click', '.nav-search .nav-link', function (e) {
    e.preventDefault();
    const header = $('.header');
    $('.search-container', header).get(0).reset();
    if(header.hasClass('search-open')) {
      header.removeClass('search-open').addClass('search-fade');
      setTimeout(function () {
        header.removeClass('search-fade');
      }, 200)
    }
    else {
      header.addClass('search-open search-fade').find('.search-container input').focus();
      setTimeout(function () {
        header.removeClass('search-fade');
      }, 200)
    }
  });

  // mobile comments
  $(document).on('click', '.open-comments', function () {
    $('.comments').addClass('open');
  }).on('click', '.close-comments', function () {
    $('.comments').removeClass('open');
  });

  // init popovers
  $('[data-toggle="popover"]').popover({
    container: 'main',
    placement: 'top',
    trigger: 'focus'
  });

  // inline editors
  $('.inline-editor').each(function () {
    new InlineEditor($(this));
  });

  // light collapsibles that go dark when expanded
  $(document).on('show.bs.collapse', '.collapse-light-dark .collapse', function () {
    $(this).closest('.collapse-light-dark').addClass('open')
  }).on('hide.bs.collapse', '.collapse-light-dark .collapse', function () {
    $(this).closest('.collapse-light-dark').removeClass('open')
  });

  // init dropdown as select
  $(document).on('click', '.dropdown-select a.dropdown-item', function(e) {
    e.preventDefault();
    $(this).closest('.dropdown').find('.selected').text($(this).text());
    $(this).addClass('active').siblings().removeClass('active');
  });

  // show/hide password
  $(document).on('click', '.toggle-pw', function () {
    const field = $('#' + $(this).data('field'));
    if (field.attr('type') === 'password') {
      field.attr('type', 'text');
      $(this).addClass('pw-shown');
    } else {
      field.attr('type', 'password');
      $(this).removeClass('pw-shown');
    }
  }).each(function () {
    const field = $('#' + $(this).data('field'));
    field.attr('type') === 'password' ? $(this).removeClass('pw-shown') : $(this).addClass('pw-shown');
  });

  // --------------- Page specific ---------------

  // User Profile (if self == user)
  if ($('#dialog-user-data').length && $('#dialog-user-password').length) {
    new PageUserProfile;
  }

  // Aktienanalyse
  if ($('.page-analysis').length) {
    new PageAnalysis;
  }

  // Registrierung
  if ($('.page-register').length) {
    new PageUserRegistration;
  }

  // Trendliste
  if ($('.page-trend-list').length) {
    new TrendList;
  }

  // Fonds/ETF
  if ($('.page-fond').length) {
    new PageFond;
  }

});


// Let the document know when the mouse is being used
document.body.addEventListener('mousedown', function () {
  document.body.classList.add('mousemode');
});

// Re-enable focus styling when Tab is pressed
document.body.addEventListener('keydown', function () {
  document.body.classList.remove('mousemode');
});

window.config = config;
