import Utils from "./utils";

export default class Tables {

  constructor() {
    this.currentList = null;
    this.currentTl = '1y';
    this.chartLookup = {};
    this.nf = new Intl.NumberFormat('de-DE');

    this.stocksFirstLoaded = false;

    //this.initTableDropdowns();
    this.initAnimatedTables();
    this.initActiveRows();
    this.initTableSwitchTl();
    this.initSortableTables();
    this.initChartDisplayTables();
  }

  // init table toggle dropdowns
  // initTableDropdowns() {
  //   $(document).on('click', '.table-dropdown a.dropdown-item', function () {
  //     const $table = $('#' + $(this).closest('.table-dropdown').data('target'));
  //     const entityId = $(this).data('table-id');
  //     console.log('initTableDropdowns() in tables.js: noch keine Funktion. Ausgewählte table-id: ', entityId); // FIXME: switch "Aktie im Vergleich zu"
  //   })
  // }

  // init animated tables
  initAnimatedTables() {
    $('.data-table.animated-rows').each(function () {
      const $table = $(this);
      const durationFade = 500;     // duration of fade in/out animation active entry
      const durationDisplay = 5000; // duration of animation while displayed, must be equal to $fade-transition in _data-tables.scss
      let displayTO = null;
      let repeated = 0;
      fadeToNext();

      $table.click(function (e) {
        if (e.which) {
          clearTimeout(displayTO);
          $table.find('tr').removeClass('fade-out');
          repeated = config.finderElementsToFade;
          if (e.target.tagName === 'A' || e.target.closest('a')) {
            $table.find('tr').removeClass('active');
            $(e.target).closest('tr').addClass('active');
          }
        }
        // if (e.target.tagName === 'A' && !$(e.target).closest('td').index()) {
        //   simulateClick(e);
        // }
      })

      function fadeToNext() {
        setTimeout(function () {
          const $active = $table.find('.active');
          $active.addClass('fade-out');
          displayTO = setTimeout(function () {
            // NOTE: does not work on "a" elements for security reasons
            //$active.removeClass('active fade-out').next().addClass('active').find('td:first-child a').trigger('click');
            // NOTE: does not work in all browsers and stops after one fade
            //$active.removeClass('active fade-out').next().addClass('active').find('td:first-child a').get(0).click();

            // Fire custom event an handle it on T5 side
            $active.removeClass('active fade-out').next().addClass('active').find('td:first-child a').trigger('trend-table:fade', repeated);
            if (++repeated < 3) {
              fadeToNext();
            }
          }, durationDisplay);
        }, durationFade);
      }

      // function simulateClick(e) {
      //   $('.trend-wrap').css({opacity: 0}).find('legend.label').text($(e.target).text());
      //   setTimeout(function () {
      //     $('.trend-wrap').removeAttr('style');
      //   }, 100)
      // }
    })
  }

  // init sortable tables
  initSortableTables() {
    const that = this;
    $(document).on('click', '.sortable-table th.sortable', function () {
      const order = $(this).hasClass('asc') ? 'desc' : 'asc';
      that.sortRows($(this).closest('.sortable-table'), $(this).index() + 1, order);
      $(this).parent().find('.sortable').removeClass('asc desc');
      $(this).addClass(order);
    })
  }

  // init active rows click
  initActiveRows() {
    $(document).on('click', '.active-rows tbody tr', function () {
      $(this).addClass('active').siblings().removeClass('active');
    });
  }

  // init timeframe switch
  initTableSwitchTl() {
    const that = this;
    const dw2url = {
      tl1mago: 'p1mPerc',
      tl1yago: 'p1yPerc',
      tl5yago: 'p5yPerc'
    }
    $(document).on('click', '.table-switch-tl li a', function (e) {
      e.preventDefault();
      $(this).addClass('active').closest('li').siblings().find('a').removeClass('active');
      const dw = $(this).data('timeline').toLowerCase();
      const $target = $($(this).closest('.tab-nav').data('target')) || false;
      if ($target && $target.length) {
        // update perf to new dw
        $target.find('tbody tr').each(function () {
          const perf = $(this).find('.perf');
          const value = String($(this).data(dw)).replace(',', '.');
          const marker = value > 0 ? 'pos' : (value < 0 ? 'neg' : 'undef');
          perf.text(value.replace('.', ',')).removeClass('pos neg undef').addClass(marker);
        });
        // update links to new dw
        $target.find('tbody tr a').each(function () {
          $(this).attr('href', $(this).attr('href').replace(/\/p\d[my]Perc/, '/' + dw2url[dw]));
        })
        // resort
        $target.find('.sortable').each(function () {
          if ($(this).hasClass('asc') || $(this).hasClass('desc')) {
            that.sortRows($target, $(this).index() + 1, $(this).hasClass('asc') ? 'asc' : 'desc');
            return false;
          }
        });
        // update dw sort header
        $target.find('th:last-child').text($(this).text());
        // re-click active entry
        $target.find('tr.active a').get(0).click();
      }
    })
  }

  // sort rows by column value
  sortRows($table, col, order) {
    const $tbody = $table.find('tbody');
    $tbody.find('tr').sort(function (a, b) {
      // We use '-' if we do not carry any data. Treat any non-numeric case as 0.
      const fieldValA = parseFloat($(`td:nth-child(${col})`, a).text().replace(',', '.')) || 0;
      const fieldValB = parseFloat($(`td:nth-child(${col})`, b).text().replace(',', '.')) || 0;
      if (order === 'asc') {
        return fieldValA - fieldValB;
      } else {
        return fieldValB - fieldValA;
      }
    }).sort(function(a, b) {
      // TREN-595 - sort all nice5 to top (currently only happening on trend-portrait)
      if ($(a).hasClass("nice5")) {
        return -1;
      }
      if ($(b).hasClass("nice5")) {
        return 1;
      }

      return 0;
    }).appendTo($tbody);
  }

  // init bootstrap tables
  initChartDisplayTables() {
    const that = this;
    const tables = [{
      selector: '.table-wrap-stocks-trends',
      url: config.xhrURLs.getTable,
      sortName: 'stock.perf',
      perfCols: ['stock.perf', 'trend.perf'],
      data: {
        list: 'best',
        tl: that.currentTl
      },
      columns: [
        {
          title: 'Aktie',
          field: 'stock.name',
          sortable: true,
          formatter: formatStock
        }, {
          title: '% 1M',
          field: 'stock.perf',
          sortable: true,
          formatter: formatPerf
        }, {
          title: 'KGV',
          field: 'stock.kgv',
          sortable: true,
          formatter: formatFloat
        }, {
          title: 'Trend',
          field: 'trend.name',
          formatter: formatTrend
        }, {
          title: '',
          field: 'trend.name',
          formatter: formatBlank
        }, {
          title: '% 1M',
          field: 'trend.perf',
          formatter: formatPerf
        }
      ]
    }, {
      selector: '.table-wrap-fonds',
      url: config.xhrURLs.getTableFonds,
      sortName: 'etf.perf',
      perfCols: ['etf.perf', 'trend.perf'],
      data: {
        list: 'fonds',
        tl: that.currentTl
      },
      columns: [
        {
          title: 'ETF',
          field: 'etf.name',
          sortable: true,
          formatter: formatFonds
        }, {
          title: '% 1M',
          field: 'etf.perf',
          sortable: true,
          formatter: formatPerf
        }, {
          title: 'TER*',
          field: 'etf.ter',
          sortable: true,
          formatter: formatFloat
        }, {
          title: 'Trend',
          field: 'trend.name',
          formatter: formatTrend
        }, {
          title: '',
          field: 'trend.name',
          formatter: formatBlank
        }, {
          title: '% 1M',
          field: 'trend.perf',
          formatter: formatPerf
        }
      ]
    }]

    tables.forEach((table) => {
      table.$bsTable = $(`${table.selector} .bootstrap-table`);
      if(table.$bsTable.length) {
        initTable(table);
        loadData(table);
        initTLswitch(table);
      }
    })


    // heading display for currentTl
    const headingsTl = {
      '1m': '% 1M',
      '1y': '% 1J',
      '5y': '% 5J',
    }

    // charts TL for currentTl
    const chartsTl = {
      '1m': 'tl1MAgo',
      '1y': 'tl1YAgo',
      '5y': 'tl5YAgo',
    }

    // click on mini charts
    $(document).on('click', '.table .link-and-chart .chart', function () {
      const $currentRow = $(this).closest('tr');
      const $bsTable = $currentRow.closest('table');
      if ($currentRow.hasClass('show-chart')) {
        closeCharts($bsTable, true);
      } else {
        closeCharts($bsTable);
        openCharts($bsTable, $currentRow);
      }
    });

    // open charts row
    function openCharts($bsTable, $currentRow) {
      const $chartStock = $currentRow.find('.chart-stock');
      const $chartTrend = $currentRow.find('.chart-trend');
      const colspan = $currentRow.find('td').length / 2;

      //$currentRow.closest('table').addClass('view-charts'); // add class to set table to grey background when charts are open

      let $chartRow = $bsTable.find('tr.charts');
      if ($chartRow.length === 0) {
        $chartRow = $(`
          <tr class="charts">
            <td colspan="${colspan}" class="cell-stock">
              <div class="wrap">
                <div class="chart chart-size-medium chart-stock" data-chart-type="1">
                  <div class="chart-perf use-bg"></div>
                  <div class="chart-legend use-bg"></div>
                  <figure class="graph"></figure>
                </div>
              </div>
            </td>
            <td colspan="${colspan}" class="cell-trend">
              <div class="wrap">
                <div class="chart chart-size-medium chart-trend" data-chart-type="1">
                  <div class="chart-perf use-bg"></div>
                  <div class="chart-legend use-bg"></div>
                  <figure class="graph"></figure>
                </div>
              </div>
            </td>
          </tr>`);
      }

      // set current timeline
      $chartRow.find('.chart').attr('data-tl', chartsTl[that.currentTl]);

      // copy data from mini chart to expanded chart
      $chartRow.find('.chart-stock')
        .attr('data-chart-id', $chartStock.data('chart-id'))
        .data('chart', $chartStock.data('chart'))
        .data('chartData', $chartStock.data('chartData'));
      $chartRow.find('.chart-trend')
        .attr('data-chart-id', $chartTrend.data('chart-id'))
        .data('chart', $chartTrend.data('chart'))
        .data('chartData', $chartTrend.data('chartData'));

      // show and re-render with same data, but new size config (small -> medium)
      $chartRow.insertAfter($currentRow.addClass('show-chart')).show();
      $chartRow.addClass('visible').find('.chart').trigger('chart:rerender');

      // scroll chart row into view
      const $container = $chartRow.closest('.fixed-table-body');
      const $element = $currentRow;

      const marginTop = $container.siblings('.fixed-table-header').height();
      const containerTop = $container.scrollTop();
      const containerBottom = containerTop + $container.height();
      const elemTop = $element.position().top - marginTop;
      const elemHeight = $currentRow.height();
      const elemBottom = elemTop + elemHeight + 280;

      if (elemBottom > containerBottom) {
        $container.stop().animate({scrollTop: elemBottom - $container.height() + 10}, 300)
      }
    }

    // close charts row
    function closeCharts($bsTable, animated) {
      const $openRow = $bsTable.find('.show-chart');
      const $chartRow = $bsTable.find('tr.charts');

      //$chartRow.closest('table').removeClass('view-charts');
      $chartRow.removeClass('visible');

      if (animated) {
        setTimeout(function () {
          $openRow.removeClass('show-chart');
          $chartRow.hide();
        }, 300)
      } else {
        $openRow.removeClass('show-chart');
      }
    }

    // cell formatters
    function formatBlank() {
      return '';
    }

    // Formatting of performance and financial values like on the server side.
    function formatFloat(value) {
      // "NaN" and "Infinity" do not exist in JSON.
      // Usually, and in our case, JSON serializers coerce to a string.
      if (null == value || 'NaN' === value || 'Infinity' === value) return '-';

      const fv = parseFloat(value).toPrecision(3);
      const fvS = that.nf.format(fv);
      return String(fvS);
    }

    function formatPerf(value) {
      return `<span class="${value < 0 ? 'neg' : 'pos'}">${formatFloat(value)}</span>`;
    }

    function formatStock(value, row) {
      return `<div class="link-and-chart cell-stock">
                <a href="${row.stock.url}">${value}</a>
                <div class="chart chart-size-small chart-stock" data-chart-id="${row.stock.chart}" data-chart-type="1" data-tl="${chartsTl[that.currentTl]}">
                  <figure class="graph"></figure>
                  <div class="close-chart"><i class="far fa-times"></i></div>
                </div>
              </div>`;
    }

    function formatTrend(value, row) {
      return `<div class="link-and-chart cell-trend">
                <a href="${row.trend.url}">${value}</a>
                <div class="chart chart-size-small chart-trend" data-chart-id="${row.trend.chart}" data-chart-type="1" data-tl="${chartsTl[that.currentTl]}">
                  <figure class="graph"></figure>
                  <div class="close-chart"><i class="far fa-times"></i></div>
                </div>
              </div>`;
    }

    function formatFonds(value, row) {
      return `<div class="link-and-chart cell-stock">
                <a href="${row.etf.url}">${value}</a>
                <div class="chart chart-size-small chart-stock" data-chart-id="${row.etf.chart}" data-chart-type="1" data-tl="${chartsTl[that.currentTl]}">
                  <figure class="graph"></figure>
                  <div class="close-chart"><i class="far fa-times"></i></div>
                </div>
              </div>`;
    }

    // set column titles to current timeframe
    function setColumnHeadings(table) {
      table.perfCols.forEach((field) => {
        table.$bsTable.bootstrapTable('updateColumnTitle', {
          field: field,
          title: headingsTl[that.currentTl]
        });

      })
    }

    // render table mini charts
    function renderCharts($bsTable) {
      $bsTable.find('.chart').each(function () {
        const $container = $(this);
        const chartId = $container.data('chart-id');
        if (that.chartLookup.hasOwnProperty(chartId)) {
          $container.data('chartData', that.chartLookup[chartId]).trigger('chart:rerender');
        } else {
          $container.trigger('chart:update').on('chart:drawn', function () {
            that.chartLookup[chartId] = $container.data('chartData');
          });
        }
      })
    }

    // init context switch (best / all)
    $(document).on('click', '.switch-trend-context li a', function (e) {
      e.preventDefault();
      $(this).addClass('active').closest('li').siblings().find('a').removeClass('active');
      that.currentList = $(this).data('context');
      const table = tables.find(obj => {
        return obj.selector = '.table-wrap-stocks-trends';
      });
      loadData(table);
    })

    // init timeline switch
    function initTLswitch(table) {
      $(document).on('click', `#${table.$bsTable.data('tab-control')} li a`, function (e) {
        e.preventDefault();
        $(this).addClass('active').closest('li').siblings().find('a').removeClass('active');
        that.currentTl = $(this).data('timeline');
        loadData(table);
      })
    }

    // data loader
    function loadData(table) {
      table.$bsTable.bootstrapTable('showLoading');
      $.ajax({
        type: 'GET',
        url: table.url,
        data: {
          list: that.currentList ? that.currentList: table.data.list,
          tl: that.currentTl
        },
        dataType: 'json',
        success: function (res) {
          if (res.status === 'success') {
            table.$bsTable.bootstrapTable('load', res.data);
            setTimeout(function () { // setting column title needs a little timeout...
              setColumnHeadings(table)
            }, 50)
          } else {
            Utils.notify(0, 'Fehler getTable');
          }
          table.$bsTable.bootstrapTable('hideLoading');
        },
        error: function (jqXHR, textStatus, errorThrown) {
          Utils.notify(0, 'Fehler getTable: ' + textStatus);
          console.log(jqXHR, textStatus, errorThrown);
        }
      })
    }

    // table initialisation
    function initTable(table) {
      table.$bsTable.bootstrapTable({
        onPostBody: function () {
          that.addNofollow();
          renderCharts(table.$bsTable);
          if (!that.stocksFirstLoaded) {
            that.stocksFirstLoaded = true;
            setTimeout(function () {
              $('.link-and-chart .chart').first().trigger('click');
            }, 1000);
          }
        },
        pagination: true,
        pageSize: 20,
        paginationParts: ['pageList'],
        paginationPreText: '<i class="far fa-long-arrow-left"></i>',
        paginationNextText: '<i class="far fa-long-arrow-right"></i>',
        paginationHAlign: '',
        search: true,
        height: 634,
        classes: 'table table-sm',
        sortName: table.sortName,
        sortOrder: 'desc',
        columns: table.columns
      });
    }
  }

  // add rel="nofollow" to pagination
  addNofollow() {
    $('.bootstrap-table .pagination a.page-link').attr('rel', 'nofollow');
  }
}
