export default class PageFond {

  constructor() {
    this.initStockListDropdown();
  }

  initStockListDropdown() {
    $(document).on('click', '.fondswitch a.dropdown-item', function (e) {
      e.preventDefault();
      const $list = $('#' + $(this).closest('.dropdown-menu').data('target'));
      if (!$list.length) return;
      $(this).addClass('active').siblings('a').removeClass('active');
      $(this).closest('.fondswitch').find('.current').text($(this).text());
      const visible = $(this).data('target');
      const $legend = $(this).closest('.listview-header').find('.chart-legend');
      $list.find('.collapse.show').collapse('hide');
      $list.find(visible).collapse('show');
      if(visible === '.profit-chart') {
        $legend.show();
        $(window).trigger('resize');
      }
      else {
        $legend.hide();
      }
    })
  }
}
